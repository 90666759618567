import {
    generageTitle,
    generateDescription,
    generateCanonical,
    generateOpenGraphTitle,
    generateOpenGraphDescription,
    generateOpenGraphImage,
    openGraphImageWidth,
    openGraphImageHeight,
    generateOpenGraphUrl,
    openGraphSiteName,
    //generateAppSmartBanner,
    socialMediaData
} from './utils';

const adaptData = ({ data, searchQuery }) => {
    const { restaurant } = data;

    return {
        title: generageTitle(restaurant),
        meta: [
            generateDescription(restaurant),
            generateOpenGraphTitle(restaurant),
            generateOpenGraphDescription(restaurant),
            generateOpenGraphImage(restaurant),
            openGraphImageWidth,
            openGraphImageHeight,
            generateOpenGraphUrl(restaurant),
            openGraphSiteName,
            //generateAppSmartBanner(restaurant),
            ...socialMediaData
        ],
        link: [
            generateCanonical(restaurant)
        ]
    }
}

export default adaptData;
