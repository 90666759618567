import { SITE_NAME, SITE_URL } from '../../../../../../config/environment';


export const generageTitle = ({ name }) => {
    return `${name} - ${SITE_NAME}`;
};

export const generateDescription = ({ name, address }) => ({
    name: 'description',
    content: `Restaurant ${name}, ${address.display}`
});

export const generateCanonical = ({ path }) => ({
    rel: 'canonical',
    href: `${SITE_URL}${path}`
});

export const generateOpenGraphTitle = ({ name }) => ({
    property: 'og:title',
    content: `${name} - ${SITE_NAME}`
});

export const generateOpenGraphDescription = ({ name, address }) => ({
    property: 'og:description',
    content: `Restaurant ${name}, ${address.display}`
});

export const openGraphImageWidth = {
    property: 'og:image:width',
    content: '800'
};

export const openGraphImageHeight = {
    property: 'og:image:height',
    content: '600'
};

export const generateOpenGraphImage = ({ name }) => ({
    property: 'og:image',
    content: 'main image'
});

export const generateOpenGraphUrl = ({ path }) => ({
    property: 'og:url',
    content: `${SITE_URL}${path}`
});

export const openGraphSiteName = {
    property: 'og:site_name',
    content: 'nearbites.com'
};

const IOS_APP_ID = 10;

export const generateAppSmartBanner = ({ path }) => ({
    name: 'applie-itunes-app',
    content: `app-id=${IOS_APP_ID}, app-argument=${SITE_URL}${path}`
});

const FACEBOOK_DATA = [
    {
        property: 'fb:app_id',
        content: 'ID'
    }
];

const TWITTER_DATA = [
    {
        property: 'twitter:card',
        content: 'summary_large_image'
    },
    {
        property: 'twitter:site',
        content: '@nearbites'
    }
];

export const socialMediaData = [...TWITTER_DATA];
