const adaptData = ({ restaurant }) => {
    const { name, path, address } = restaurant;
    
    return {
        name,
        path,
        address
    }
};

export default adaptData;
