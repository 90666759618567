import React from 'react';
import styled from 'styled-components';
import {
    paletteHelper,
    spacingHelper,
    H2,
    H3,
    Text,
    Button,
    Stack,
    Inline,
    Card,
    Sprite,
    typographyHelper
} from '../../../../@nitty'
import Link from '../../../../components/link'
import { TemplatedLazyImage } from '../../../../components/image';
import placeholderSrc from './placeholder.svg';


const renderMenu = (meals) => {
    if (!meals) return <div>Currently no menu</div>

    return (
        <MenuUL>
            {meals.map((meal, index) => {
                const { name, path, images, price, mainImage, description } = meal;
                return (
                    <MenuLI key={index}>
                        <MealCard>
                            <meta itemProp="name" content={name} />
                            <meta itemProp="url" content={path} />
                            <CardLink to={path} rel="noopener noreferrer nofollow"></CardLink>
                            <MealCardBody>
                                <MealCardInfoPanel>
                                    <Stack inset="small">
                                        <Stack gap="extraSmall">
                                            <StyledH2>
                                                <CardLink to={path} rel="noopener noreferrer nofollow">
                                                    <MealName>{name}</MealName>
                                                </CardLink>
                                            </StyledH2>
                                        </Stack>
                                        <Inline grow={false} gap="small">
                                            <Price>{price?.display}</Price>
                                        </Inline>
                                    </Stack>
                                </MealCardInfoPanel>
                                <MealCardImageWrapper>
                                    <MealCardPicture
                                        templatedUrl={mainImage?.templatedUrl || placeholderSrc}
                                        lazy={true}
                                        placeholder={placeholderSrc}
                                        size="250x250"
                                        options="fit=cover"
                                        alt={name}
                                        title={name}
                                    />
                                </MealCardImageWrapper>
                            </MealCardBody>
                        </MealCard>
                    </MenuLI>
                );
            })}
        </MenuUL>
    )
};

const View = ({ meals }) => {
  return (
    <Wrapper>
        <SectionTitle>Menu</SectionTitle>
        {renderMenu(meals)}
    </Wrapper>
  );
};

export default View;

const Wrapper = styled.div`
    margin-bottom: 1rem;

    ${({ theme }) => theme.layoutBreakpoints.medium} {
        margin-bottom: 1.5rem;
    }
`;

const SectionTitle = styled(H3)`
    margin-bottom: 1rem;
`;

const MenuUL = styled.ul`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
    gap: 1.5rem;
    justify-items: center;
`

const MenuLI = styled.li`
    width: 100%;
`;

const MealCard = styled.div`
    position: relative;
    width: 100%;
`;

const MealCardBody = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
`;
const MealCardImageWrapper = styled.div`
    border-radius: 0.75rem;
    overflow: hidden;
    width: 150px;
    height: 150px;
`;

const MealCardPicture = styled(TemplatedLazyImage)`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    background-color: ${({ theme }) => `${theme.palette.backgroundSecondary}`};
`;

const MealCardInfoPanel = styled.div`
    flex: 1;
`;

const CardLink = styled(Link)`
    display: block;
    text-decoration: none;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        border-radius: 0.75rem;
    }
`;

const Price = styled(Text).attrs(() => ({
    as: 'div'
}))`
    color: ${paletteHelper("textSecondary")};
`;

const MealName = styled(Text).attrs(() => ({
    as: 'span',
}))`
    ${typographyHelper("body01")}
    ${typographyHelper("strong")}
    color: ${paletteHelper("textPrimary")};
`;

const StyledH2 = styled(H2)`
    line-height: 1rem;
`