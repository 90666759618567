import { hot } from 'react-hot-loader';
import React from 'react';
import View from './View';

export { default as fetchData } from './fetchData';

const RestaurantDetailsView = ({ data, location }) => {
  return <View data={data} location={location} />
};

export const RestaurantDetails = hot(module)(RestaurantDetailsView);
