import React from 'react';
import useSearch from '../../../../util/search/useSearch';
import View from '../../../../components/meta-data/View';
import adaptData from './adaptData';

const MetaData = (props) => {
    return <View {...adaptData(props)} {...props} />
};

export default MetaData;
