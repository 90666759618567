import React from 'react';
import styled from 'styled-components';
import { H1 } from '../../../../@nitty'

const Wrapper = styled.div`
    margin-bottom: 1.5rem;

    ${({ theme }) => theme.layoutBreakpoints.medium} {
        margin-bottom: 2rem;
    }
`;


const View = ({name, path, address}) => {
    const { display } = address;
  return (
    <Wrapper>
        <H1>{name}</H1>
        <div>{display}</div>
    </Wrapper>
  );
};

export default View;
